import * as React from 'react';

import type { PageProps } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';

import { HeroBlock } from 'stile-shared/src/components/2020/blocks/HeroBlock';
import { TextBlock } from 'stile-shared/src/components/2020/blocks/TextBlock';
import { H2, P } from 'stile-shared/src/components/2020/common/Primitives';
import { Center } from 'stile-shared/src/components/2020/layout/Center';

import { getPageData } from 'utils/getPageData';

import { PageLayout } from 'templates/2020/PageLayout';

import { CtaBlock } from 'components/blocks/CtaBlock';
import { NextPageBlock } from 'components/blocks/NextPageBlock';

export { Head } from 'templates/2020/Head';

function OurPrinciplesPage(props: PageProps) {
  const { hero } = getPageData(props.location.pathname);

  return (
    <PageLayout {...props}>
      <HeroBlock
        {...hero}
        image={
          <StaticImage
            loading="eager"
            aspectRatio={2.37}
            src="../../../../stile-shared/assets/images/our-principles/cover_our_principles.png"
            alt="A teacher shows smiling students how to use a pipette"
          />
        }
      />

      <TextBlock>
        <P>
          Countless questions arise when creating science resources; questions such as “How can we
          truly enhance learning in the classroom?” and “How do we most clearly explain this
          concept?”. To help us best answer these questions, and more, we are constantly guided by
          our principles.
        </P>
      </TextBlock>

      <TextBlock>
        <Center max="450px">
          <StaticImage
            src="../../../../stile-shared/assets/images/our-principles/spot_principles_teachers.png"
            alt="An illustration of a teacher explaining something to a student, who has a tablet and workbook"
          />
        </Center>
        <H2>Teachers are of the utmost importance, we need to support them</H2>
        <P>
          We believe that teachers are the single most important part of a student’s education. We
          want to help teachers perform at their best, and believe that a great learning resource
          should both save teachers time and provide them with powerful learning tools.
        </P>
      </TextBlock>

      <TextBlock>
        <Center max="450px">
          <StaticImage
            src="../../../../stile-shared/assets/images/our-principles/spot_principles_prepare.png"
            alt="An illustration of two female scientists. One is looking at a diagram of gears, and the other is reaching for something with a clipboard in hand"
          />
        </Center>
        <H2>Prepare students for life, not just exams</H2>
        <P>
          A great education extends beyond the curriculum. We believe that it is our duty to provide
          students with the skills and knowledge they need to prosper in life, not just to pass
          their exams.
        </P>
      </TextBlock>

      <TextBlock>
        <Center max="450px">
          <StaticImage
            src="../../../../stile-shared/assets/images/our-principles/spot_principles_onesize.png"
            alt="An illustration of three buildings. The center one has a bright green border around most of it, like a cropping tool"
          />
        </Center>
        <H2>One size doesn’t fit all</H2>
        <P>
          Every school is different, every teacher is different and every student is different. We
          believe that learning resources should cater to a wide range of students and should be
          easy for teachers to customize for their students, as needed.
        </P>
      </TextBlock>

      <TextBlock>
        <Center max="450px">
          <StaticImage
            src="../../../../stile-shared/assets/images/our-principles/spot_principles_technology.png"
            alt="An illustration of a teacher explaining a demonstration she is holding, with a bigger diagram on the board behind her"
          />
        </Center>
        <H2>Technology should empower teachers, not replace them</H2>
        <P>
          Many online resources sideline the teacher, leaving students in isolation. We believe the
          teacher should be in the driver’s seat. Classroom technology should empower teachers,
          helping to enhance their practice, not change it.
        </P>
      </TextBlock>

      <TextBlock>
        <Center max="450px">
          <StaticImage
            src="../../../../stile-shared/assets/images/our-principles/spot_principles_quality.png"
            alt="An illustration of some of the components of a Stile lesson, including an embedded video and a diagram"
          />
        </Center>
        <H2>Quality is paramount</H2>
        <P>
          Our team has a relentless commitment to quality and are constantly striving to make Stile
          better. This commitment is what sets us apart, whether it’s our lessons, platform,
          professional development or even our customer support.
        </P>
        <P>
          Take our curriculum design process: all of our lessons are created by our in-house team of
          teachers, writers, illustrators and videographers. Every lesson is thoroughly tested in
          the classroom prior to release and lessons are reviewed for accuracy and quality by
          teachers and practicing scientists. Instead of cutting corners and using freely available
          (yet often poor quality) resources from the internet, Stile’s team creates custom-made
          images, videos and simulations to ensure that students are consistently met with high
          quality, age-appropriate content in every lesson.
        </P>
      </TextBlock>

      <NextPageBlock path="/who-we-are/our-team/" />

      <CtaBlock />
    </PageLayout>
  );
}

export default OurPrinciplesPage;
